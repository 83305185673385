<template>
  <el-dialog :visible.sync="dialog" :close-on-click-modal="false" :title="isAdd ? '新增角色' : '编辑角色'" append-to-body @closed="form = null" width="500px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px" v-if="form">
      <el-form-item label="角色名称" prop="name">
        <el-input v-model="form.name" style="width: 370px;" />
      </el-form-item>
      <!-- <el-form-item label="角色级别" prop="sort">
        <el-input-number v-model.number="form.level" :min="1" controls-position="right" style="width: 370px;" />
      </el-form-item> -->
      <!-- <el-form-item label="数据范围">
        <el-select v-model="form.dataScope" filterable style="width: 370px" placeholder="请选择数据范围" @change="changeScope">
          <el-option
            v-for="item in dateScopes"
            :key="item"
            :label="item"
            :value="item"/>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.dataScope === '自定义'" label="数据权限">
        <treeselect v-model="deptIds" :options="depts" multiple style="width: 370px" placeholder="请选择" />
      </el-form-item>-->
      <el-form-item label="描述信息">
        <el-input v-model="form.remark" style="width: 370px;" rows="5" type="textarea" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getDepts } from "@/api/dept";
import { add, edit } from "@/api/role";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dateScopes: ["全部", "本级", "自定义"],
      loading: false,
      dialog: false,
      depts: [],
      deptIds: [],
      form: null,
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }]
      }
    };
  },
  methods: {
    doSubmit() {
      if (this.form.dataScope === "自定义" && this.deptIds.length === 0) {
        this.$message({
          message: "自定义数据权限不能为空",
          type: "warning"
        });
      } else {
        // this.form.depts = [];
        // if (this.form.dataScope === "自定义") {
        //   for (let i = 0; i < this.deptIds.length; i++) {
        //     this.form.depts.push({
        //       id: this.deptIds[i]
        //     });
        //   }
        // }
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.loading = true;
            if (this.isAdd) {
              this.doAdd();
            } else this.doEdit();
          } else {
            return false;
          }
        });
      }
    },
    doAdd() {
      add(this.form)
        .then(res => {
          this.resetForm();
          this.$notify({
            title: "添加成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.$parent.init();
          this.dialog = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    doEdit() {
      edit(this.form)
        .then(res => {
          this.resetForm();
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.$parent.init();
          this.dialog = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    resetForm(form, entId = null) {
      this.form = form || {
        name: "",
        depts: [],
        remark: "",
        dataScope: "本级",
        level: 1,
        enterpriseId: entId
      };
      this.dialog = true;
    },
    getDepts() {
      getDepts({ enabled: true }).then(res => {
        this.depts = res.content;
      });
    },
    changeScope() {
      if (this.form.dataScope === "自定义") {
        this.getDepts();
      }
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
/deep/ .el-input-number .el-input__inner {
  text-align: left;
}
</style>
